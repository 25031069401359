import Showdown from 'showdown'
import { mutations as baseStoreMutations } from './index'

export const state = () => ({
  companyName: '',
  contactRequested: false,
  companyId: '',
  stat: 'all',
  dateRange: { from: null, to: null },
  dateInterval: 'weeks',
  selectedDatePreset: '3M',
  shipmentTypes: ['shipper', 'consignee', 'internal'],
  shipmentsData: {
    shipper: { shipments: [], totalCount: 0, color: '' },
    consignee: { shipments: [], totalCount: 0, color: '' },
    internal: { shipments: [], totalCount: 0, color: '' },
    all: { totalCount: 0, color: '' }
  },
  overviewData: {
    shipper: { shipments: [], totalCount: 0, color: '' },
    consignee: { shipments: [], totalCount: 0, color: '' },
    internal: { shipments: [], totalCount: 0, color: '' },
    all: { totalCount: 0, color: '' }
  },
  overviewTopTradersCount: {
    consignee: 0,
    shipper: 0
  },
  // Used by consignees & suppliers
  pageLimit: 20,
  sortField: 'calculatedTeu',
  sortOrder: 'desc',
  isImporter: false,
  isSupplier: false,
  contacting: false,
  isFetchingDetails: false,
  isCustomViewOpen: false,
  profiles: {},
  ratings: {
    rating: 0
  }
})

export const mutations = {
  resetState(currentState) {
    currentState.shipmentsData = state().shipmentsData
    currentState.companyName = ''
    currentState.companyId = ''
  },
  setCompanyName(state, companyName) {
    state.companyName = companyName
  },
  setContactRequested(state, contactRequested) {
    state.contactRequested = contactRequested
  },
  setCompanyId(state, companyId) {
    state.companyId = companyId
  },
  setStat(state, stat) {
    state.stat = stat
  },
  setShipmentsData(state, shipmentsData) {
    state.shipmentsData = shipmentsData
  },
  setSorting(state, { sortField, sortOrder }) {
    state.sortField = sortField
    state.sortOrder = sortOrder
  },
  setPageLimit(state, pageLimit) {
    state.pageLimit = pageLimit
  },
  setDateRange: baseStoreMutations.setDateRange,
  setIsImporter(state, importer) {
    state.isImporter = importer
  },
  setIsSupplier(state, supplier) {
    state.isSupplier = supplier
  },
  setContacting(state, contacting) {
    state.contacting = contacting
  },
  setFetchingDetails(state, fetching) {
    state.isFetchingDetails = fetching
  },
  setCustomViewOpen(state, open) {
    state.isCustomViewOpen = open
  },
  setDateInterval(state, interval) {
    state.dateInterval = interval
  },
  setOverviewData(state, overviewData) {
    state.overviewData = overviewData
  },
  setOverviewTopTradersCount(state, overviewTopTradersCount) {
    state.overviewTopTradersCount = overviewTopTradersCount
  },
  setProfiles(state, profiles) {
    const converter = new Showdown.Converter({
      simpleLineBreaks: true
    })
    if (profiles.Overview) {
      profiles.Overview = converter.makeHtml(profiles.Overview)
    }
    if (profiles.middle_points) {
      profiles.middle_points = converter.makeHtml(profiles.middle_points)
    }
    if (profiles.Product) {
      profiles.Product = converter.makeHtml(profiles.Product)
    }
    if (profiles.Competitors) {
      profiles.Competitors = converter.makeHtml(profiles.Competitors)
    }
    if (profiles.Finance) {
      profiles.Finance = converter.makeHtml(profiles.Finance)
    }
    if (profiles.News) {
      profiles.News = converter.makeHtml(profiles.News)
    }
    state.profiles = profiles
  },
  setRatings(state, ratings) {
    state.ratings = ratings
  }
}

export const getters = {
  companyModules(currentState) {
    if (!currentState) {
      return []
    }
    const defaultState = state()
    return Object.keys(currentState).reduce((modules, key) => {
      if (!(key in defaultState)) {
        modules.push(key)
      }
      return modules
    }, [])
  },
  templateFields(state) {
    return [
      {
        id: 'index',
        text: '#'
      },
      {
        id: 'name',
        text: state.stat,
        sortable: true
      },
      {
        id: 'shipmentCount',
        text: 'No. of Shipments',
        sortable: true
      },
      {
        id: 'lastAddress',
        text: 'Last Address'
      },
      {
        id: 'mostRecentDate',
        text: 'Most Recent',
        sortable: true
      },
      {
        id: 'containersCount',
        text: 'No. of Containers',
        sortable: true
      },
      {
        id: 'grossWeightInKgs',
        text: 'Gross Weight Kgs',
        sortable: true
      },
      {
        id: 'calculatedTeu',
        text: 'TEU',
        sortable: true
      }
    ]
  },
  arrivalDateBetween(state) {
    const { from, to } = state.dateRange
    return { from: from / 1000, to: to / 1000 }
  },
  // used for downloading company profile data
  companyCriteria: (state, getters) => (options = {}) => {
    const params = {}
    const { from, to } = getters.arrivalDateBetween

    params.companyName = state.companyName
    params.arrivalDates = `${from}-${to}`

    const sort = state.sortOrder === 'desc' ? '-' : ''
    params.sortBy = `${sort}${state.sortField}`

    return {
      ...params,
      ...options
    }
  },
  companySortBy(state) {
    const sort = state.sortOrder === 'desc' ? '-' : ''
    const sortBy = `${sort}${state.sortField}`

    return sortBy
  }
}

export const actions = {
  resetCompanyStates({ commit, getters }) {
    commit('resetState')
    getters.companyModules.forEach(module => {
      commit(`company/${module}/resetState`, null, { root: true })
    })
  },
  async initialize({ dispatch }) {
    await Promise.all([
      dispatch('fetchDetails'),
      dispatch('fetchShipmentsData'),
      dispatch('fetchOverviewData')
      // dispatch('fetchTradersStatus')
    ])
  },
  async prefetchModules({ dispatch, getters }, currentModule) {
    const exemptions = [currentModule, 'visualmap', 'importers', 'suppliers']
    return Promise.all(
      getters.companyModules.map(module => {
        if (exemptions.includes(module)) {
          return
        }
        return dispatch(`company/${module}/initialize`, null, { root: true })
      })
    )
  },
  async changeDateRange({ commit, dispatch }, { from, to }) {
    commit('setDateRange', { from, to })

    dispatch('company/overview/initialize', null, { root: true })
    dispatch('company/fetchShipmentsData', null, { root: true })
    dispatch('company/importers/initialize', null, { root: true })
    dispatch('company/suppliers/initialize', null, { root: true })
    dispatch('company/shipments/fetchShipments', {}, { root: true })
  },
  async fetchDetails({ commit, state, dispatch }) {
    try {
      commit('setFetchingDetails', true)
      const { data } = await this.$axios.$get(
        `${COMPANY_API_URL}/companies/${state.companyId}/header`
      )

      commit('setCompanyName', data.name)
      commit('setContactRequested', data.contactRequested)
      commit('setFetchingDetails', false)
      dispatch('fetchCompanyProfiles')
    } catch (error) {
      if (this.$axios.isCancel(error)) return
      throw error
    }
  },
  async fetchTradersStatus({ state, commit }) {
    try {
      const { data } = await this.$axios.$get(
        `${COMPANY_API_URL}/companies/${state.companyId}/details`
      )

      commit('setIsImporter', data.shipmentCountAsConsignee > 0)
      commit('setIsSupplier', data.shipmentCountAsShipper > 0)
      commit('setOverviewTopTradersCount', {
        consignee: data.shipmentCountAsConsignee,
        shipper: data.shipmentCountAsShipper
      })
    } catch (error) {
      if (this.$axios.isCancel(error)) return
      throw error
    }
  },
  async fetchShipmentsData({ state, rootState, getters, commit }) {
    const requestId = 'company/fetchShipmentsData'
    this.$axios.cancel(requestId)

    const { dateInterval, companyId, shipmentTypes } = state
    const { country, tradeType } = rootState
    const url = `${COMPANY_API_URL}/companies/${companyId}/shipments`
    const { from, to } = getters.arrivalDateBetween
    const params = {
      arrivalDates: `${from}-${to}`,
      interval: dateInterval,
      size: 0,
      traderTypes: shipmentTypes.join(','),
      country: `${country}`,
      shipmentType: `${tradeType}`
    }
    let response = null

    try {
      response = await this.$axios.$get(url, { params, requestId })
    } catch (error) {
      if (this.$axios.isCancel(error)) {
        return // request has been canceled
      }
      throw error
    }

    const shipmentsData = shipmentTypes.reduce((types, type) => {
      types[type] = {
        shipments: [],
        totalCount: 0
      }
      return types
    }, {})
    let allTotalCount = 0
    response.data.forEach(({ start, end, totalShipments, traderType }) => {
      const traderData = shipmentsData[traderType]
      traderData.totalCount += totalShipments
      traderData.shipments.push({
        from: start,
        to: end,
        count: totalShipments
      })

      if (traderType === 'internal') {
        allTotalCount -= totalShipments
        return
      }
      allTotalCount += totalShipments
    })

    shipmentsData.all = {
      totalCount: allTotalCount
    }

    commit('setShipmentsData', shipmentsData)
  },
  async setPageLimit({ commit }, pageLimit) {
    commit('setPageLimit', pageLimit)
    commit('setCurrentPage', 1)
  },
  async setSorting({ commit }, payload) {
    commit('setSorting', payload)
    commit('setCurrentPage', 1)
  },
  async contactCompany({ rootState, commit, dispatch }, companyId) {
    commit('setContacting', true)

    try {
      const { country, tradeType: shipmentType } = rootState
      await this.$axios.$post(
        `${COMPANY_API_URL}/companies/${companyId}/requests`,
        {
          params: {
            country,
            shipmentType
          }
        }
      )
    } catch (e) {
      console.error(e)
    }

    await dispatch('fetchDetails')
    await dispatch('userCredits/getUserCredits', {}, { root: true })
    commit('setContacting', false)
  },
  async fetchOverviewData({ state, rootState, commit }) {
    const requestId = 'company/fetchOverviewData'
    this.$axios.cancel(requestId)

    const { companyId, shipmentTypes } = state
    const { country, tradeType } = rootState
    const url = `${COMPANY_API_URL}/companies/${companyId}/shipments`
    const params = {
      arrivalDates: `${rootState.minDate}-${rootState.maxDate}`,
      interval: `months`,
      size: 0,
      traderTypes: shipmentTypes.join(','),
      country: `${country}`,
      shipmentType: `${tradeType}`
    }
    let response = null

    try {
      response = await this.$axios.$get(url, { params, requestId })
    } catch (error) {
      if (this.$axios.isCancel(error)) {
        return // request has been canceled
      }
      throw error
    }

    const overviewShipmentsData = shipmentTypes.reduce((types, type) => {
      types[type] = {
        shipments: [],
        totalCount: 0
      }
      return types
    }, {})
    let allTotalCount = 0
    response.data.forEach(({ start, end, totalShipments, traderType }) => {
      const traderData = overviewShipmentsData[traderType]
      traderData.totalCount += totalShipments
      traderData.shipments.push({
        from: start,
        to: end,
        count: totalShipments
      })

      if (traderType === 'internal') {
        allTotalCount -= totalShipments
        return
      }
      allTotalCount += totalShipments
    })

    overviewShipmentsData.all = {
      totalCount: allTotalCount
    }

    commit('setOverviewData', overviewShipmentsData)
  },
  async fetchCompanyProfiles({ state, commit }) {
    const clientId = this.$auth.user.id
    const profilesResponse = await this.$axios.$post(
      `${COMPANY_PROFILE_LAMBDA_URL}/clients/${clientId}/companies/profiles/ai/generate`,
      {
        companyName: state.companyName
      }
    )

    commit('setProfiles', profilesResponse.data)
  },
  editRating({ state, commit, dispatch }, rating) {
    const ratings = {
      ...state.ratings,
      rating: rating
    }
    commit('setRatings', ratings)
    dispatch('updateRating')
  },
  async updateRating({ state, commit }) {
    const clientId = this.$auth.user.id

    const ratings = await this.$axios.$post(
      `${IMPORTSCAN_AWS_URL}/clients/${clientId}/companies/profiles/ai/rate`,
      {
        companyName: state.companyName,
        rating: state.ratings.rating
      }
    )

    commit('setRatings', ratings.debug)
  }
}
