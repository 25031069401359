import _ from 'lodash'
import * as config from '~/config/config'
import $date from '../plugins/date/methods'
import { updateKeywordStructure } from '~/utils/advancedSearch'
import { Status } from '~/utils/constants/shipmentAlerts'

export const state = () => ({
  isInitialized: false,
  isInitializing: false,
  initializingPage: false,
  searchKeywords: [],
  refinedBy: [],
  miscFilters: [],
  currentSearchId: '',
  searchIdForBookmark: null,
  searchToken: null,
  refinedByFields: [],
  suggestions: [],
  keywords: {},
  shipments: [],
  totalShipments: 0,
  currentPage: 1,
  pageLimit: 20,
  pageCount: 0,
  sortField: 'ArrivalDate',
  sortOrder: 'desc',
  pageLimits: [20, 40, 60, 100],
  sortOrders: ['desc', 'asc'],
  stat: 'shipments',
  stats: ['shipments', 'weight', 'containers'],
  numZeroResults: 0,
  chartData: [],
  chartTotals: [],
  searchHistory: [],
  bookmarks: [],
  savedSearches: [],
  selectedRefinedByMobile: null,
  availableMiscFilters: [],
  currentBookmark: { id: '', name: '' },
  bookmarkMinDate: null,
  bookmarkMaxDate: null,
  bookmarkDateRange: { from: null, to: null },
  bookmarksPage: 1,
  bookmarksLimit: 20,
  lastRow: false,
  isComponentLoading: false,
  preFetchSearchKeywords: [],
  suggestionFields: [],
  preFetchedCounts: {},
  isUsingPagination: false,
  isCustomViewOpen: false,
  criteriaSuggestions: {},
  dateRangeInvalidError: false,
  shipmentAlerts: [],
  shipmentAlert: {},
  shipmentAlertStatus: Status.ACTIVE,
  shipmentAlertId: '',
  shipmentAlertResult: {
    shipmentAlertId: '',
    resultId: '',
    name: '',
    triggerDate: 0,
    criteria: {}
  },

  // for search date range component
  isDatePickerVisible: false
})

// noinspection JSAnnotator
export const getters = {
  searchKeywords(state) {
    return updateKeywordStructure(state.searchKeywords)
  },
  allowedMiscFilters(state, getters, { country }) {
    return config.miscFilters.filter(filter => {
      if (filter.fieldName === 'ExcludeBlankShipper') {
        return ['ec', 'pe', 'ru', 'ua'].indexOf(country) !== -1
      }

      if (filter.fieldName === 'ExcludeBlankConsignee') {
        return ['ru', 'ua', 'us'].indexOf(country) !== -1
      }

      if (
        ['ExcludeMaster', 'HouseOnly', 'MasterOnly'].indexOf(
          filter.fieldName
        ) !== -1
      ) {
        return country === 'us'
      }

      return true
    })
  },
  defaultAllowedMiscFilters(_state, getters) {
    const miscFilterVals = getters.allowedMiscFilters.map(e => e.value)

    return ['BlankConsigneesExcluded', 'BlankShippersExcluded'].filter(
      defaultMiscFilter => {
        if (miscFilterVals.indexOf(defaultMiscFilter) > -1) {
          return true
        }

        return false
      }
    )
  },
  refinedByGroupedByField(state) {
    return state.refinedBy.reduce((acc, item) => {
      if (!acc[item.field]) {
        acc[item.field] = []
      }

      acc[item.field].push(item)
      return acc
    }, {})
  },
  hasSearch(state) {
    return !_.isArray(state.searchKeywords) || state.searchKeywords.length
  },
  isShipmentAlertSearch(state, getters, rootState) {
    if (rootState.rootLink)
      return rootState.rootLink.includes('/shipment-alert')

    return false
  },
  // Used in add bookmark and generate download
  searchCriteria: (state, getters, rootState, rootGetters) => (
    options = {}
  ) => {
    const params = {}
    const sort = state.sortOrder === 'desc' ? '-' : ''

    params.keywords = getters.hasSearch ? state.searchKeywords : []

    params.include = state.refinedBy
      .filter(refinement => refinement.condition === 'include')
      .map(refinement => ({
        keyword: refinement.name,
        field: refinement.field
      }))
    params.exclude = state.refinedBy
      .filter(refinement => refinement.condition === 'exclude')
      .map(refinement => ({
        keyword: refinement.name,
        field: refinement.field
      }))

    params.misc = state.miscFilters

    const { from, to } = rootGetters.arrivalDateBetween
    params.arrivalDates = `${from}-${to}`
    params.page = state.currentPage
    params.limit = state.pageLimit
    params.sortBy = `${sort}${state.sortField}`

    return {
      ...params,
      ...options
    }
  },
  preFetchCountQueryParam: (state, getters, rootState) => options => {
    const params = new URLSearchParams()
    const hasPreFetchSearchKeywords = !_.isArray(state.preFetchSearchKeywords)

    if (hasPreFetchSearchKeywords) {
      params.append('keyword', JSON.stringify(state.preFetchSearchKeywords))
    }

    const aggregates = ['Consignee', 'Shipper']
    aggregates.forEach(aggregate => {
      params.append(`aggregates[]`, aggregate)
    })
    state.refinedBy.forEach(refinement => {
      params.append(
        `${refinement.condition}[]`,
        `${refinement.name},${refinement.field}`
      )
    })

    const { from, to } = options.dateRange || rootState.dateRange
    params.append('arrivalDates', `${from / 1000}-${to / 1000}`)

    const misc = options.misc || getters.defaultAllowedMiscFilters
    params.append('misc', misc.join(','))

    return { params }
  },
  searchQueryParam: (state, getters, rootState) => (options = []) => {
    const params = new URLSearchParams()
    const sort = state.sortOrder === 'desc' ? '-' : ''

    if (getters.hasSearch) {
      params.append('keyword', JSON.stringify(state.searchKeywords))
    }

    state.refinedBy.forEach(refinement => {
      params.append(
        `${refinement.condition}[]`,
        `${refinement.name},${refinement.field}`
      )
    })
    const { from, to } = rootState.dateRange
    params.append('arrivalDates', `${from / 1000}-${to / 1000}`)
    params.append('page', state.currentPage)
    params.append('limit', state.pageLimit)
    params.append('sortBy', `${sort}${state.sortField}`)
    if (state.miscFilters.length) {
      params.append('misc', state.miscFilters.join(','))
    }
    options.forEach(option => {
      params.append(option.key, option.value)
    })

    return { params }
  },
  dateRangeText(state, getters, rootState) {
    const {
      twoWeeksAgo,
      oneWeekAgo,
      oneYearAgo,
      threeMonthsAgo,
      oneMonthAgo,
      sixMonthsAgo,
      threeYearsAgo,
      end
    } = $date.getCommonDates(rootState.maxDate)
    const { from, to } = rootState.dateRange
    if (end === to) {
      const texts = {
        [oneYearAgo]: 'for the past year',
        [threeMonthsAgo]: 'for the past 3 months',
        [oneMonthAgo]: 'for the past month',
        [twoWeeksAgo]: 'for the past 2 weeks',
        [oneWeekAgo]: 'for the past week',
        [sixMonthsAgo]: 'for the past 6 months',
        [threeYearsAgo]: 'for the past 3 years'
      }

      return texts[from]
    }
    const toFormat = timestamp => {
      const date = new Date(timestamp)
      return $date.formatUtc(date, 'yyyy-MM-dd')
    }

    return `from ${toFormat(from)} to ${toFormat(to)}`
  },
  activeViewPageLimit(state, getters, rootState) {
    const activeAggregateView = rootState.views.activeAggregateView
    const aggregateNamespace = `aggregated${_.capitalize(activeAggregateView)}`

    if (activeAggregateView === 'shipments') return state.pageLimit
    else if (
      activeAggregateView === 'analytics' ||
      activeAggregateView === 'overview'
    )
      return null

    return rootState.search[aggregateNamespace].pageLimit
  },
  activeViewCurrentPage(state, getters, rootState) {
    const activeAggregateView = rootState.views.activeAggregateView
    const aggregateNamespace = `aggregated${_.capitalize(activeAggregateView)}`

    if (activeAggregateView === 'shipments') return state.currentPage
    else if (
      activeAggregateView === 'analytics' ||
      activeAggregateView === 'overview'
    )
      return null
    return rootState.search[aggregateNamespace].currentPage
  },
  criteriaSuggestions(state) {
    return state.criteriaSuggestions
  },
  hasCurrentSearch(state) {
    return state.currentSearchId !== null
  },
  shipmentAlertResult(state) {
    return state.shipmentAlertResult
  },
  shipmentsData(state) {
    return state.shipments
  }
}

export const mutations = {
  initialize(state) {
    state.isInitialized = true
  },
  setInitializingPage(state, done) {
    state.initializingPage = done
  },
  setInitializing(state, done) {
    state.isInitializing = done
  },
  setStat(state, stat) {
    state.stat = stat
  },
  resetState(currentState) {
    Object.assign(currentState, state())
  },
  setShipments(state, { shipments, totalShipments }) {
    state.shipments = shipments
    state.totalShipments = totalShipments
  },
  setSuggestions(state, suggestions) {
    state.suggestions = suggestions
  },
  setKeywords(state, keywords) {
    state.keywords = keywords
  },
  clearKeywords(state) {
    state.keywords = {}
  },
  setCurrentPage(state, page) {
    state.currentPage = +page
  },
  setPaginationStatus(state, status) {
    state.isUsingPagination = status
  },
  setPageLimit(state, pageLimit) {
    state.pageLimit = +pageLimit
  },
  setPageCount(state, count) {
    state.pageCount = count
  },
  setSearchId(state, searchId) {
    state.currentSearchId = searchId
  },
  setShipmentAlertIds(state, { resultId, shipmentAlertId }) {
    state.shipmentAlertResult.shipmentAlertId = shipmentAlertId
    state.shipmentAlertResult.resultId = resultId
  },
  setShipmentAlertResult(state, payload) {
    state.shipmentAlertResult = payload
  },
  clearRefinedBy(state) {
    state.refinedBy = []
  },
  clearRefinedByField(state, field) {
    state.refinedBy = _.filter(state.refinedBy, r => r.field !== field)
  },
  clearMiscFilters(state) {
    state.miscFilters = []
  },
  setSearchToken(state, token) {
    state.searchToken = token
  },
  setRefinedBy(state, { field, name, condition }) {
    state.refinedBy = [
      ..._.filter(
        state.refinedBy,
        r => r.field !== field || r.name.toLowerCase() !== name.toLowerCase()
      ),
      { field, name, condition }
    ]
  },
  removeRefinedBy(state, { field, name }) {
    state.refinedBy = _.filter(
      state.refinedBy,
      r => r.field !== field || r.name.toLowerCase() !== name.toLowerCase()
    )
  },
  addMiscFilter(state, miscFilter) {
    const specialFilters = [
      'MasterShipmentsExcluded',
      'HouseShipmentsOnly',
      'MasterShipmentsOnly'
    ]

    if (specialFilters.indexOf(miscFilter) > -1) {
      state.miscFilters = _.filter(
        state.miscFilters,
        m => specialFilters.indexOf(m) === -1
      )
    }

    state.miscFilters = [...state.miscFilters, miscFilter]
  },
  setMiscFilters(state, miscFilters) {
    state.miscFilters = miscFilters
  },
  removeMiscFilter(state, miscFilter) {
    state.miscFilters = _.filter(state.miscFilters, m => m !== miscFilter)
  },
  setSorting(state, { sortField, sortOrder }) {
    state.sortField = sortField
    state.sortOrder = sortOrder
  },
  setRefinedByFields(state, refinedByFields) {
    state.refinedByFields = refinedByFields
  },
  setBookmarks(state, bookmarks) {
    state.bookmarks = bookmarks
  },
  setSearchHistory(state, searchHistory) {
    state.searchHistory = searchHistory
  },
  addBookmark(state, bookmark) {
    state.bookmarks = [bookmark, ...state.bookmarks]
  },
  editBookmark(state, { index, name }) {
    state.bookmarks[index].name = name
  },
  deleteBookmark(state, id) {
    state.bookmarks = state.bookmarks.filter(s => s.bookmarkId !== id)
  },
  setSelectedRefinedByMobile(state, selectedRefinedByMobile) {
    state.selectedRefinedByMobile = selectedRefinedByMobile
  },
  setChartData(state, { items, totals }) {
    state.chartData = items
    state.chartTotals = totals
  },
  addSearchKeyword(state, keyword) {
    if (_.isArray(state.searchKeywords)) {
      state.searchKeywords = { o: 'AND', i: [] }
    }
    state.searchKeywords.i.push(keyword)
  },
  editSearchKeyword(state, { keyword, key, value }) {
    state.searchKeywords = state.searchKeywords.map(el => {
      if (_.isEqual(el, keyword)) {
        el[key] = value
      }
      return el
    })
  },
  deleteSearchKeyword(state, keyword) {
    state.searchKeywords = state.searchKeywords.filter(
      el => !_.isEqual(el, keyword)
    )
    if (keyword.key === 0) {
      state.searchKeywords = state.searchKeywords.map((el, id) => {
        if (id === 0) {
          el.operator = 'and'
        }
        return el
      })
    }
    state.searchKeywords = state.searchKeywords.map((el, id) => {
      el.key = id
      return el
    })
  },
  clearSearchKeywords(state) {
    state.searchKeywords = []
  },
  clearPreFetchedResultCounts(state) {
    state.preFetchedCounts = {}
  },
  setPreFetchedResultCounts(state, payload) {
    state.preFetchedCounts = payload
  },
  setPreFetchSearchKeywords(state, preFetchSearchKeywords) {
    state.preFetchSearchKeywords = preFetchSearchKeywords
  },
  setSearchKeywords(state, searchKeywords) {
    state.searchKeywords = searchKeywords
  },
  setAvailableMiscFilters(state, filters) {
    state.availableMiscFilters = filters
  },
  setNumZeroResults(state, num) {
    state.numZeroResults = num
  },
  setCurrentBookmark(state, { id, name }) {
    state.currentBookmark = { id, name }
  },
  clearCurrentBookmark(state) {
    state.currentBookmark = { id: '', name: '' }
  },
  setBookmarkDateRange(state, { from, to }) {
    if (from) {
      state.bookmarkDateRange.from = from
    }
    if (to) {
      state.bookmarkDateRange.to = to
    }
  },
  setBookmarkDateLimits(state, { minDate, maxDate }) {
    if (minDate) {
      state.bookmarkMinDate = minDate
    }
    if (maxDate) {
      state.bookmarkMaxDate = maxDate
    }
  },
  addToBookmarks(state, bookmarks) {
    state.bookmarks = [...state.bookmarks, ...bookmarks]
  },
  setBookmarksPage(state, page) {
    state.bookmarksPage = page
  },
  setLastRow(state, last) {
    state.lastRow = last
  },
  setSavedSearches(state, searches) {
    state.savedSearches = searches
  },
  addSavedSearch(state, search) {
    state.savedSearches = [search, ...state.savedSearches]
  },
  editSavedSearch(state, editedSearch) {
    // This forces an update to make components reactive to the state change
    const newSavedSearches = [...state.savedSearches]
    const index = newSavedSearches.findIndex(
      search => search.id === editedSearch.id
    )
    newSavedSearches[index] = { ...newSavedSearches[index], ...editedSearch }
    state.savedSearches = newSavedSearches
  },
  deleteSavedSearch(state, id) {
    state.savedSearches = state.savedSearches.filter(search => search.id !== id)
  },
  setComponentLoading(state, isLoading) {
    state.isComponentLoading = isLoading
  },
  setSuggestionFields(state, fields) {
    state.suggestionFields = fields
  },
  setCustomViewOpen(state, open) {
    state.isCustomViewOpen = open
  },
  setSearchIdForBookmark(state, searchId) {
    state.searchIdForBookmark = searchId
  },
  setCriteriaSuggestions(state, criteriaSuggestions) {
    state.criteriaSuggestions = criteriaSuggestions
  },
  setDateRangeInvalidError(state, isInvalid) {
    state.dateRangeInvalidError = isInvalid
  },
  setShipmentAlertStatus(state, status) {
    state.shipmentAlertStatus = status
  },
  setShipmentAlert(state, shipmentAlertData) {
    state.shipmentAlert = shipmentAlertData
  },
  setAllShipmentAlerts(state, shipmentAlerts) {
    state.shipmentAlerts = shipmentAlerts
  },
  addShipmentAlert(state, shipmentAlert) {
    state.shipmentAlerts = [shipmentAlert, ...state.shipmentAlerts]
  },
  editShipmentAlert(state, { index, emailAddress, updatedOn }) {
    state.shipmentAlerts[index].emailAddress = emailAddress
    state.shipmentAlerts[index].updatedOn = updatedOn
  },
  deleteShipmentAlert(state, id) {
    state.shipmentAlerts = state.shipmentAlerts.filter(
      s => s.shipmentAlertId !== id
    )
  },
  setShipmentAlertId(state, id) {
    state.shipmentAlertId = id
  },
  setIsDatePickerVisible(state, isVisible) {
    state.isDatePickerVisible = isVisible
  }
}

export const actions = {
  async initialize({ state, commit, dispatch }, route) {
    if (state.isInitialized || state.isInitializing) {
      // no need to refetch on page changes
      return
    }
    commit('setInitializing', true)
    await dispatch('showErrorUserCredits', null, { root: true })

    commit('setBookmarkDateRange', {
      from: new Date(0), //1970
      to: Date.now()
    })

    let fetchSearches = 'getTokenAndFetchShipment'
    let searchId = null
    let p = null
    if (route && route.params) {
      if (route.params.searchId) {
        fetchSearches = 'fetchShipmentsBySearchId'
        searchId = route.params.searchId
      }

      p = parseInt(route.query.p)

      let r = parseInt(route.query.r)
      if (state.pageLimits.includes(r) && r != 20) {
        dispatch('commitActiveViewPageLimit', r)
      }
    }

    const promises = [
      dispatch('fetchSearchHistory'),
      dispatch('fetchBookmarks'),
      dispatch('fetchShipmentAlerts')
    ]

    if (searchId) {
      promises.push(dispatch(fetchSearches, { searchId, p }))
    }

    await Promise.all(promises)

    commit('initialize')
    commit('setInitializing', false)
    commit('setInitializingPage', false)
    dispatch('loadThirdPartyScripts', null, { root: true })
  },
  async fetchSuggestionFields(
    { rootState, rootGetters, commit, dispatch },
    keyword
  ) {
    if (!keyword) {
      return
    }

    let { from, to } = rootGetters['userSubscriptions/subscriptionDateRange']
    from = from / 1000
    to = to / 1000

    try {
      const { data } = await this.$axios.$get(
        `${SHIPMENTS_API_URL}/v1/${rootState.country}/${
          rootState.tradeType
        }/shipments/suggested/fields`,
        {
          params: {
            keyword,
            arrivalDates: `${from}-${to}`
          },
          progress: false
        }
      )
      commit('setSuggestionFields', data)
    } catch (e) {
      if (this.$axios.isCancel(e)) return
      dispatch('checkInvalidDateRangeError', e)
      commit('setSuggestionFields', [])
      console.error(e)
    }
  },
  async fetchSuggestions({ state, dispatch, commit }, keyword) {
    try {
      await dispatch('fetchSuggestionFields', keyword)
      dispatch('prefetchKeywords', {
        keyword,
        fields: state.suggestionFields
      })
      commit('setSuggestions', ['All Fields', ...state.suggestionFields])
    } catch (e) {
      commit('setSuggestions', [])
    }
  },
  async fetchCriteriaSuggestions(
    { rootState, commit, getters, dispatch },
    params
  ) {
    if (!params) params = getters.searchCriteria()

    const {
      arrivalDates,
      exclude = [],
      include = [],
      keywords: keyword,
      misc = [],
      country = 'us',
      shipmentType = 'import'
    } = params
    try {
      const { data } = await this.$axios.$get(
        `${SHIPMENTS_API_URL}/v1/${rootState.country}/${
          rootState.tradeType
        }/shipments/suggested/criteria`,
        {
          params: {
            arrivalDates,
            exclude,
            include,
            keyword,
            misc: misc.join(','),
            country,
            shipmentType
          }
        }
      )

      commit('setCriteriaSuggestions', data)
    } catch (error) {
      if (this.$axios.isCancel(error)) return
      dispatch('checkInvalidDateRangeError', error)
    }
  },
  async prefetchKeywords(
    { rootState, rootGetters, commit, dispatch },
    { keyword, fields }
  ) {
    if (fields.length < 1 || !keyword) {
      return
    }
    commit('clearKeywords')

    let { from, to } = rootGetters['userSubscriptions/subscriptionDateRange']
    from = from / 1000
    to = to / 1000

    try {
      const { data } = await this.$axios.$get(
        `${SHIPMENTS_API_URL}/v1/${rootState.country}/${
          rootState.tradeType
        }/shipments/suggested/keywords`,
        {
          params: {
            fields: fields.join(','),
            keyword,
            arrivalDates: `${from}-${to}`
          },
          progress: false
        }
      )

      commit('setKeywords', data)
    } catch (error) {
      if (this.$axios.isCancel(error)) return
      dispatch('checkInvalidDateRangeError', error)
      console.error(error)
    }
  },
  async setDateRange({ commit, dispatch }, dateRange) {
    commit('setComponentLoading', true)
    commit('setDateRange', dateRange, { root: true })
    commit('clearRefinedBy')
    dispatch('resetCurrentPageOfAllViews')

    /** this is unused for now */
    // if (getters.hasSearch || state.refinedBy.length) {
    //   dispatch('fetchRefinedBy')
    // }
  },
  async setDefaultMiscFilters({ getters, commit }) {
    commit('clearMiscFilters')

    getters.defaultAllowedMiscFilters.forEach(defaultAllowedMiscFilter => {
      commit('addMiscFilter', defaultAllowedMiscFilter)
    })
  },
  async setSorting({ commit, dispatch }, payload) {
    commit('setSorting', payload)
    commit('setCurrentPage', 1)
    dispatch('fetchShipments')
  },
  async setPageLimit({ state, commit, dispatch }, pageLimit) {
    if (state.pageLimit === pageLimit) return

    commit('setPageLimit', pageLimit)
    commit('setCurrentPage', 1)
    dispatch('fetchShipments')
  },
  async setCurrentPage({ state, commit, dispatch }, page) {
    if (state.currentPage === page) return

    commit('setCurrentPage', page)
    commit('setPaginationStatus', true)
    dispatch('fetchShipments')
  },
  async fetchSearchHeaderToken(
    { state, getters, commit, dispatch, rootState },
    payload
  ) {
    try {
      const searchId = payload && payload.searchId ? payload.searchId : null
      const response = await this.$axios.$get(
        `${SHIPMENTS_API_URL}/v2/${rootState.country}/${
          rootState.tradeType
        }/search/token?type=${payload.type}`,
        {
          ...getters.searchQueryParam(
            searchId ? [{ key: 'searchId', value: searchId }] : []
          )
        }
      )

      const searchToken = response.searchToken
      commit('setSearchToken', searchToken)

      if (
        !Array.isArray(state.searchKeywords) ||
        state.searchKeywords.length !== 0
      ) {
        if (searchId) {
          commit('setSearchId', searchId)
        } else {
          if (state.isUsingPagination) {
            commit('setSearchId', state.currentSearchId)
          } else {
            commit('setSearchId', response.searchId)
          }
        }
      } else {
        commit('setSearchId', null)
      }

      commit('clearCurrentBookmark')
      if (response.bookmark) {
        commit('setCurrentBookmark', {
          id: response.bookmark.bookmarkId,
          name: response.bookmark.name
        })
      }

      commit('setShipmentAlertStatus', Status.ACTIVE)
      if (response.shipmentAlertId) {
        commit('setShipmentAlertId', response.shipmentAlertId)
        commit('setShipmentAlertStatus', Status.CREATED)
      }

      if (response.searchId) {
        commit('setSearchIdForBookmark', response.searchId)
      } else {
        commit('setSearchIdForBookmark', null)
      }
    } catch (error) {
      if (this.$axios.isCancel(error)) {
        return
      }

      dispatch('checkInvalidDateRangeError', error)
      if (state.dateRangeInvalidError) {
        this.$router.push('/search?view=overview')
      }

      // Handle messages from subscription-related errors
      const searchId = payload && payload.searchId
      const errorData = (error && error.response && error.response.data) || ''
      const errorMessage = (errorData && errorData.reason) || ''
      const errorType = (errorData && errorData.type) || ''

      if (searchId && errorType.toLowerCase() === 'subscriptionexception') {
        commit(
          'setError',
          {
            show: true,
            type: 'subscription',
            searchId,
            message: errorMessage
          },
          { root: true }
        )
      }

      throw error
    }
  },
  async preFetchResultCount(
    { commit, getters, dispatch, rootState },
    options = {}
  ) {
    const requestId = 'preFetchResultCount'
    this.$axios.cancel(requestId)

    try {
      const response = await this.$axios.$get(
        `${SHIPMENTS_API_URL}/v1/${rootState.country}/${
          rootState.tradeType
        }/shipments/search/count`,
        {
          ...getters.preFetchCountQueryParam(options),
          requestId
        }
      )

      commit('setPreFetchedResultCounts', response.data)
    } catch (err) {
      if (this.$axios.isCancel(err)) return
      dispatch('checkInvalidDateRangeError', err)
      if (state.dateRangeInvalidError) return
      throw err
    }
  },
  checkInvalidDateRangeError({ commit, dispatch }, err) {
    commit('setDateRangeInvalidError', false)

    const response = err.response ? err.response.data : { reason: '' }
    if (
      response.reason &&
      response.reason.includes(
        'Date range given is not valid in your subscription'
      )
    ) {
      dispatch('fetchSettings', false, { root: true })
      commit('setDateRangeInvalidError', true)
      console.error(err)
    }
  },
  async fetchShipments(
    { state, commit, dispatch, getters, rootState },
    payload
  ) {
    let p = null
    if (payload) {
      p = payload.p ? payload.p : null
    }

    const requestId = 'fetchShipments'
    this.$axios.cancel(requestId)
    try {
      const response = await this.$axios.$get(
        `${SHIPMENTS_API_URL}/v4/${rootState.country}/${
          rootState.tradeType
        }/shipments/search`,
        {
          ...getters.searchQueryParam(),
          requestId,
          headers: {
            ...(state.searchToken != null
              ? { 'x-search-token': state.searchToken }
              : {})
          }
        }
      )

      const shipments = response.data
      const totalShipments = response.total

      // Uncomment if first login tour feature will be use again
      // detect zero search results for tour
      // if (
      //   !totalShipments &&
      //   (!rootState.auth.user.zero_results_state ||
      //     rootState.tours.FirstLoginTour.currentStep !== -1)
      // ) {
      //   commit(
      //     'tours/toggleTour',
      //     {
      //       tour: 'FirstLoginTour',
      //       show: false
      //     },
      //     { root: true }
      //   )

      //   commit(
      //     'tours/toggleTour',
      //     {
      //       tour: 'NoResultsTour',
      //       show: true
      //     },
      //     { root: true }
      //   )
      // }

      // customer.io track consecutive zero search results
      if (!totalShipments && process.env.CUSTOMERIO_SITE_ID) {
        commit('setNumZeroResults', rootState.search.numZeroResults + 1)

        if (rootState.search.numZeroResults >= 5) {
          global._cio.track('consecutive_zero_search_results')
          commit('setNumZeroResults', 0)
        }
      } else {
        commit('setNumZeroResults', 0)
      }
      if (!totalShipments && response.params) {
        dispatch('fetchCriteriaSuggestions', response.params)
      } else {
        // revert to null values
        commit('setCriteriaSuggestions', {})
      }

      // assign data to variable
      commit('setShipments', { shipments, totalShipments })
      // end of assigning data to variable

      if (p && p > 1) {
        const total = Math.min(10000, totalShipments)
        const pageCount = Math.ceil(total / state.pageLimit)

        if (p > pageCount) {
          dispatch('setCurrentPage', pageCount)
        }
        commit('setInitializingPage', true)
      }

      commit('setComponentLoading', false)
    } catch (error) {
      const response = error.response ? error.response.data : ''

      if (
        response.reason === 'Daily search limit exceeded' ||
        this.$axios.isCancel(error)
      ) {
        return
      }

      throw error
    }
  },
  async fetchShipmentsBySearchId({ commit, dispatch }, { searchId, p }) {
    try {
      const response = await this.$axios.$get(
        `${USERS_API_URL}/v1/search/history/${searchId}`,
        { progress: false }
      )
      if (!response.success) {
        commit('setSearchId', null)
        if (p && p > 1) commit('setCurrentPage', p)
        if (this.$router.currentRoute.path === '/search') {
          this.$router.push('/search')
          dispatch('getTokenAndFetchShipment')
        }
        return
      } else {
        commit('setSearchId', searchId)
      }

      dispatch('searchFromCriteria', {
        criteria: response.data.criteria,
        searchId
      })
      commit('setInitializingPage', true)
    } catch (error) {
      if (this.$axios.isCancel(error)) {
        return // request has been canceled
      }
      throw error
    }
  },
  async fetchShipmentAggregates({ dispatch }) {
    return Promise.all([
      dispatch('search/aggregatedShippers/fetchAggregateShippers', null, {
        root: true
      }),
      dispatch('search/aggregatedConsignees/fetchAggregatedConsignees', null, {
        root: true
      })
    ])
  },
  async fetchShipmentsAndShipmentAggregates({ dispatch }, payload) {
    return Promise.all([
      dispatch('fetchShipments', payload),
      dispatch('fetchShipmentAggregates', payload)
    ])
  },
  async searchFromCriteria(
    { commit, dispatch, rootGetters },
    { criteria, type = 'search', searchId }
  ) {
    const { keywords, include, exclude, misc, sortBy, arrivalDates } = criteria

    const sortOrder = sortBy[0] === '-' ? 'desc' : 'asc'
    const sortField = sortBy[0] === '-' ? sortBy.substr(1) : sortBy

    commit('clearSearchKeywords')
    commit('clearRefinedBy')
    commit('clearMiscFilters')

    commit('setSorting', { sortField, sortOrder })
    commit('setSearchKeywords', keywords)

    const includeRefinements = include.map(refinement => ({
      condition: 'include',
      field: refinement.field,
      name: refinement.keyword
    }))
    const excludeRefinements = exclude.map(refinement => ({
      condition: 'exclude',
      field: refinement.field,
      name: refinement.keyword
    }))
    const combinedRefinements = [...includeRefinements, ...excludeRefinements]
    combinedRefinements.forEach(refinement =>
      commit('setRefinedBy', refinement)
    )
    misc.forEach(filter => commit('addMiscFilter', filter))

    if (arrivalDates) {
      const parsedDates = arrivalDates.split('-')
      const [from, to] = parsedDates
      const arrivalDateBetween = { from: +from * 1000, to: +to * 1000 }
      commit('setDateRange', arrivalDateBetween, { root: true })

      if (type === 'shipment-alert') {
        const isWithinSubscription = rootGetters[
          'userSubscriptions/withinSubscriptionDateRange'
        ](arrivalDateBetween)

        if (!isWithinSubscription) {
          commit('setComponentLoading', false)
          dispatch('showShipmentAlertError', null, { root: true })
          throw Error('Invalid subscription')
        }
      }
    }

    await dispatch('search', { searchId, type })
  },
  async fetchRefinedBy({ rootState, commit, state, getters }) {
    const requestId = 'fetchRefinedBy'
    this.$axios.cancel(requestId)

    try {
      const { data } = await this.$axios.$get(
        `${SHIPMENTS_API_URL}/v1/${rootState.country}/${
          rootState.tradeType
        }/shipments/search/refinements`,
        {
          ...getters.searchQueryParam([
            {
              key: 'fields',
              value: 'All'
            }
          ]),
          requestId,
          progress: false,
          headers: {
            ...(state.searchToken
              ? { 'x-search-token': state.searchToken }
              : {})
          }
        }
      )

      const miscConfig = config.miscFilters.map(m => m.value)

      const miscFilters = data.filter(f => miscConfig.indexOf(f.field) > -1)

      const availableRefinements = data.filter(
        f => f.category && f.category !== 'Miscellaneous'
      )

      state.refinedBy.map(refinement => {
        const fieldIndex = availableRefinements.findIndex(
          field => field.field === refinement.field
        )
        if (
          availableRefinements[fieldIndex].refinements.findIndex(
            f => f.name.toLowerCase() === refinement.name.toLowerCase()
          ) < 0
        ) {
          availableRefinements[fieldIndex].refinements.unshift({
            name: refinement.name,
            count: 0
          })
        }
      })

      commit('setAvailableMiscFilters', miscFilters)
      commit('setRefinedByFields', availableRefinements)
    } catch (error) {
      if (this.$axios.isCancel(error)) {
        return // request has been canceled
      }
      throw error
    }
  },
  async search({ dispatch, commit }, { searchId, type } = {}) {
    commit('setComponentLoading', true)
    commit('setPaginationStatus', false)
    commit('setAvailableMiscFilters', [])
    commit('setRefinedByFields', [])
    commit('setSidebarContent', false, { root: true })

    await dispatch('resetBookmarksSettings')
    await dispatch('getTokenAndFetchShipment', { searchId, type })

    if (type === 'search') {
      commit('setCurrentPage', 1)
    }

    // if (getters.hasSearch || state.refinedBy.length) {
    //   dispatch('fetchRefinedBy')
    // }

    await dispatch('userCredits/getUserCredits', null, {
      root: true
    })
    dispatch('fetchSearchHistory')
    dispatch('fetchBookmarks')
    dispatch('fetchShipmentAlerts')
  },
  async fetchShipmentAlertResult(
    { dispatch, commit },
    { shipmentAlertId, resultId }
  ) {
    try {
      const response = await this.$axios.$get(
        `${SHIPMENTS_API_URL}/v1/shipment-alert/${shipmentAlertId}/${resultId}`,
        { progress: false }
      )

      const { data } = response

      commit('setShipmentAlertResult', data)

      await dispatch('searchFromCriteria', {
        criteria: {
          ...response.data.criteria,
          sortBy: `-ArrivalDate`
        },
        type: 'shipment-alert'
      })

      commit('setSearchId', null)
    } catch (error) {
      throw error
    }
  },
  async getTokenAndFetchShipment({ dispatch }, payload) {
    try {
      await dispatch('fetchSearchHeaderToken', payload)
    } catch (error) {
      const errorType =
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.type) ||
        ''

      await dispatch('clearSearch')

      if (errorType.toLowerCase() === 'subscriptionexception') {
        console.error(error)
        return
      } else {
        throw error
      }
    }

    return Promise.all([
      dispatch('fetchShipmentAggregates', payload),
      dispatch('analytics/fetchAnalyticsData', null, {
        root: true
      }),
      dispatch('fetchShipments', payload)
    ])
  },
  async fetchSearchHistory({ commit, rootState }) {
    try {
      const { id: clientId } = this.$auth.user

      const searchHistory = await this.$axios.$get(
        `${USERS_API_URL}/v1/client/${clientId}/search/history`,
        {
          params: {
            page: 1,
            limit: 40,
            country: rootState.country,
            shipmentType: rootState.tradeType
          },
          progress: false
        }
      )

      commit('setSearchHistory', searchHistory.data || [])
    } catch (error) {
      if (this.$axios.isCancel(error)) {
        return // request has been canceled
      }
      throw error
    }
  },
  async fetchBookmarks({ state, commit }) {
    try {
      const { id: clientId } = this.$auth.user
      const { from, to } = state.bookmarkDateRange
      // if page has been moved, we'll fetch all records up to that page
      const fetchAll = state.bookmarksPage > 1
      // convert 'to' parameter to end of day
      const eodTo = new Date(to).setHours(23, 59, 59) / 1000
      const { data } = await this.$axios.$get(
        `${USERS_API_URL}/v1/client/${clientId}/bookmarks`,
        {
          params: {
            dates: `${from / 1000}-${eodTo}`,
            page: 1,
            size: fetchAll
              ? state.bookmarksPage * state.bookmarksLimit
              : state.bookmarksLimit
          },
          progress: false
        }
      )
      commit('setBookmarks', data || [])
      if (data.length < state.bookmarksLimit) {
        commit('setLastRow', true)
      }
    } catch (error) {
      if (this.$axios.isCancel(error)) {
        return // request has been canceled
      }
      throw error
    }
  },
  async fetchMoreBookmarks({ state, commit }) {
    try {
      const { id: clientId } = this.$auth.user
      const { from, to } = state.bookmarkDateRange
      // convert 'to' parameter to end of day
      const eodTo = new Date(to).setHours(23, 59, 59) / 1000
      const { data } = await this.$axios.$get(
        `${USERS_API_URL}/v1/client/${clientId}/bookmarks`,
        {
          params: {
            dates: `${from / 1000}-${eodTo}`,
            page: state.bookmarksPage + 1,
            size: state.bookmarksLimit
          }
        }
      )
      if (!data.length) {
        commit('setLastRow', true)
        return
      }
      commit('setBookmarksPage', state.bookmarksPage + 1)
      commit('addToBookmarks', data)
    } catch (error) {
      if (this.$axios.isCancel(error)) {
        return // request has been canceled
      }
      throw error
    }
  },
  async createBookmark({ state, commit, getters, rootState }, name) {
    try {
      const { id: clientId } = this.$auth.user
      const { shipments, weight, containers } = state.chartTotals
      const { data } = await this.$axios.$post(
        `${USERS_API_URL}/v1/client/${clientId}/bookmarks`,
        {
          name,
          country: rootState.country,
          shipmentType: rootState.tradeType,
          criteria: getters.searchCriteria(),
          view: rootState.views.activeShipmentsView.name,
          shipments,
          weight,
          containers,
          searchId: state.searchIdForBookmark
        },
        { progress: false }
      )
      commit('addBookmark', data)
      commit('setCurrentBookmark', {
        id: data.bookmarkId,
        name: data.name
      })
      return data
    } catch (err) {
      console.error(err)
    }
  },
  async bookmarkSearchAgain(
    { rootState, dispatch, rootGetters },
    { bookmarkId, searchId, searchCriteria }
  ) {
    if (!searchId) {
      const { data } = await this.$axios.$post(
        `${USERS_API_URL}/v1/${rootState.country}/${
          rootState.tradeType
        }/search/validate`,
        {
          bookmarkId,
          searchCriteria
        }
      )
      if (data.searchId) {
        searchId = data.searchId
        await dispatch('fetchShipmentsBySearchId', {
          searchId,
          p: 1
        })
      } else {
        await dispatch('searchFromCriteria', {
          criteria: searchCriteria
        })
      }
    } else {
      await dispatch('fetchShipmentsBySearchId', {
        searchId,
        p: 1
      })
    }
    this.$router.push(rootGetters.currentSearchURL)
  },
  async updateBookmark({ commit, state }, { id, name }) {
    const { id: clientId } = this.$auth.user
    const { data } = await this.$axios.$put(
      `${USERS_API_URL}/v1/client/${clientId}/bookmarks/${id}`,
      { name },
      { progress: false }
    )
    const bookmarkIndex = state.bookmarks.findIndex(
      bookmark => bookmark.bookmarkId === data.bookmarkId
    )
    if (bookmarkIndex > -1) {
      commit('editBookmark', { index: bookmarkIndex, ...data })
    }
    if (state.currentBookmark.id === data.bookmarkId) {
      commit('setCurrentBookmark', {
        id: data.bookmarkId,
        name: data.name
      })
    }
  },
  async removeBookmark({ commit, state }, bookmarkId) {
    try {
      const { id: clientId } = this.$auth.user

      await this.$axios.$delete(
        `${USERS_API_URL}/v1/client/${clientId}/bookmarks/${bookmarkId}`,
        {
          progress: false
        }
      )
      const bookmarkIndex = state.bookmarks.findIndex(
        bookmark => bookmark.bookmarkId === bookmarkId
      )
      if (bookmarkIndex > -1) {
        commit('deleteBookmark', bookmarkId)
      }
      if (state.currentBookmark.id === bookmarkId) {
        commit('clearCurrentBookmark')
      }
    } catch (error) {
      // Catch for failed delete, likely due to removing
      // a bookmark too early while it's still being added
      if (error.response.data.reason === 'Saved Search ID does not exist') {
        return
      }
      throw error
    }
  },
  async fetchSavedSearches({ commit, rootState }) {
    try {
      const { id: clientId } = this.$auth.user
      const { data } = await this.$axios.$get(
        `${USERS_API_URL}/v1/client/${clientId}/search/saved`,
        {
          params: {
            country: rootState.country,
            shipmentType: rootState.tradeType
          },
          progress: false
        }
      )

      commit('setSavedSearches', data)
    } catch (error) {
      if (this.$axios.isCancel(error)) {
        return // request has been canceled
      }
      throw error
    }
  },
  async addSavedSearch({ state, commit, rootState, getters }, name) {
    try {
      const { id: clientId } = this.$auth.user

      let criteria = { ...getters.searchCriteria() }

      const { data } = await this.$axios.$post(
        `${USERS_API_URL}/v1/client/${clientId}/search/saved`,
        {
          country: rootState.country,
          shipmentType: rootState.tradeType,
          name,
          criteria: { ...criteria },
          hasEmailAlert: false,
          shipmentCount: state.chartTotals.shipments
        },
        {
          headers: {
            ...(state.searchToken
              ? { 'x-search-token': state.searchToken }
              : {})
          }
        }
      )
      commit('addSavedSearch', data)
    } catch (error) {
      if (this.$axios.isCancel(error)) {
        return // request has been canceled
      }
      throw error
    }
  },
  async editSavedSearch({ commit, dispatch }, search) {
    try {
      const { id: clientId } = this.$auth.user
      const { data } = await this.$axios.$patch(
        `${USERS_API_URL}/v1/client/${clientId}/search/saved/${search.id}`,
        {
          ...search
        }
      )
      commit('editSavedSearch', { ...data[0], id: search.id })
      dispatch('userCredits/getUserCredits', null, { root: true })
    } catch (error) {
      if (this.$axios.isCancel(error)) {
        return // request has been canceled
      }
      throw error
    }
  },
  async deleteSavedSearch({ commit, dispatch }, search) {
    try {
      const { id: clientId } = this.$auth.user
      await this.$axios.$delete(
        `${USERS_API_URL}/v1/client/${clientId}/search/saved/${search.id}`
      )
      commit('deleteSavedSearch', search.id)
      dispatch('userCredits/getUserCredits', null, { root: true })
    } catch (error) {
      if (this.$axios.isCancel(error)) {
        return // request has been canceled
      }
      throw error
    }
  },
  clearFilters({ commit }) {
    commit('clearRefinedBy')
    commit('clearMiscFilters')
  },
  async clearSearch({ dispatch, commit }) {
    // clear search criteria
    dispatch('clearFilters')
    dispatch('resetCurrentPageOfAllViews')
    commit('setAvailableMiscFilters', [])
    commit('setRefinedByFields', [])
    commit('clearSearchKeywords')
    dispatch('resetDateRange', null, { root: true })

    // clear search-related data
    commit('setSearchId', null)
    commit('clearCurrentBookmark')
    commit('setShipmentAlertStatus', Status.ACTIVE)
    commit('setSearchIdForBookmark', null)
    commit('setCriteriaSuggestions', {})

    // set display to blank search state
    commit('setIsSideBarShown', true, { root: true })
    commit('setSidebarContent', true, { root: true })

    //clear shipment data
    commit('setShipments', { shipments: [], totalShipments: 0 })
    dispatch('search/aggregatedShippers/resetShippersData', null, {
      root: true
    })
    dispatch('search/aggregatedConsignees/resetConsigneesData', null, {
      root: true
    })

    commit('setComponentLoading', false)
  },
  async fetchShipmentGraph({ state, commit, rootState, getters }) {
    const requestId = 'fetchShipmentGraph'
    this.$axios.cancel(requestId)
    try {
      const response = await this.$axios.$get(
        `${SHIPMENTS_API_URL}/v1/${rootState.country}/${
          rootState.tradeType
        }/shipments/summary`,
        {
          ...getters.searchQueryParam([
            {
              key: 'scale',
              value: rootState.dateInterval
            },
            {
              key: 'timezone',
              value: rootState.timezone
            }
          ]),
          requestId,
          progress: false,
          headers: {
            ...(state.searchToken
              ? { 'x-search-token': state.searchToken }
              : {})
          }
        }
      )

      // @TODO change after { success: "false" } fix
      if (response.success === false) {
        response.items = []
        response.total = {}
      }

      const formattedBody = {
        ...response,
        items: response.data.map(item => ({
          ...item,
          items: item.items.sort(
            (a, b) => (a.label.split('-')[0] > b.label.split('-')[0] ? 1 : -1)
          )
        }))
      }

      commit('setChartData', formattedBody)
    } catch (error) {
      if (this.$axios.isCancel(error)) {
        return // request has been canceled
      }
      throw error
    }
  },
  async modifySearchKeyword({ commit, dispatch }, keyword) {
    commit('editSearchKeyword', keyword)
    dispatch('search')
    dispatch('resetCurrentPageOfAllViews')
  },
  async removeSearchKeyword({ state, commit, dispatch }, keyword) {
    commit('deleteSearchKeyword', keyword)
    if (state.searchKeywords.length === 0) {
      dispatch('clearSearch')
      return
    }
    dispatch('search')
    dispatch('resetCurrentPageOfAllViews')
  },
  async setBookmarkDateRange({ commit, dispatch }, dateRange) {
    await dispatch('resetBookmarksSettings')
    commit('setBookmarkDateRange', dateRange)
    dispatch('fetchBookmarks')
  },
  async resetBookmarksSettings({ commit }) {
    commit('setBookmarksPage', 1)
    commit('setLastRow', false)
  },
  resetCurrentPageOfAllViews({ commit }) {
    commit('setCurrentPage', 1)
    commit('search/aggregatedConsignees/setCurrentPage', 1, {
      root: true
    })
    commit('search/aggregatedShippers/setCurrentPage', 1, {
      root: true
    })
  },
  commitActiveViewCurrentPage({ rootState, commit }, page) {
    const activeAggregateView = rootState.views.activeAggregateView

    if (
      activeAggregateView === 'analytics' ||
      activeAggregateView === 'overview'
    )
      return

    const aggregateNamespace = `aggregated${_.capitalize(activeAggregateView)}`

    if (activeAggregateView === 'shipments') {
      commit('setCurrentPage', page)
    } else {
      commit(`search/${aggregateNamespace}/setCurrentPage`, page, {
        root: true
      })
    }
  },
  commitActiveViewPageLimit({ rootState, commit }, limit) {
    const activeAggregateView = rootState.views.activeAggregateView

    if (
      activeAggregateView === 'analytics' ||
      activeAggregateView === 'overview'
    )
      return

    // @TODO repetitive
    const aggregateNamespace = `aggregated${_.capitalize(activeAggregateView)}`

    if (activeAggregateView === 'shipments') {
      commit('setPageLimit', limit)
    } else {
      commit(`search/${aggregateNamespace}/setPageLimit`, limit, {
        root: true
      })
    }
  },
  async fetchDownloadSearchId({ rootState }, { searchCriteria, exportId }) {
    try {
      const { searchId } = await this.$axios.$patch(
        `${SHIPMENTS_API_URL}/v1/client/downloads/search`,
        {
          searchCriteria: {
            country: rootState.country,
            shipmentType: rootState.tradeType,
            ...searchCriteria
          },
          exportId: exportId
        }
      )

      return searchId
    } catch (error) {
      if (this.$axios.isCancel(error)) {
        return // request has been canceled
      }
      throw error
    }
  },
  async fetchShipmentAlerts({ rootState, commit }) {
    try {
      const { id: clientId } = this.$auth.user
      const { data } = await this.$axios.$get(
        `${USERS_API_URL}/client/${clientId}/search/alerts`,
        {
          params: {
            country: rootState.country,
            shipmentType: rootState.tradeType
          },
          progress: false
        }
      )
      commit('setAllShipmentAlerts', data || [])
    } catch (error) {
      if (this.$axios.isCancel(error)) {
        return // request has been canceled
      }
      throw error
    }
  },
  async createShipmentAlert(
    { rootState, getters, commit, dispatch },
    { name, emailAddress }
  ) {
    const { keywords, include, exclude, misc } = getters.searchCriteria()
    const { country, tradeType: shipmentType } = rootState
    const { id: clientId } = this.$auth.user
    try {
      const { data } = await this.$axios.$post(
        `${USERS_API_URL}/client/${clientId}/search/alerts`,
        {
          country,
          shipmentType,
          name,
          emailAddress,
          criteria: { country, shipmentType, keywords, include, exclude, misc }
        },
        { progress: false }
      )

      commit('addShipmentAlert', data)
      commit('setShipmentAlertId', data.shipmentAlertId)
      commit('setShipmentAlertStatus', Status.CREATED)
      dispatch('userCredits/getUserCredits', null, { root: true })
    } catch (err) {
      throw err
    }
  },
  async updateShipmentAlert({ commit, state }, { id, emailAddress }) {
    const { id: clientId } = this.$auth.user
    const { data } = await this.$axios.$patch(
      `${USERS_API_URL}/client/${clientId}/search/alerts/${id}`,
      { emailAddress },
      { progress: false }
    )
    const shipmentAlertIndex = state.shipmentAlerts.findIndex(
      shipmentAlert => shipmentAlert.shipmentAlertId === data.shipmentAlertId
    )
    if (shipmentAlertIndex > -1) {
      commit('editShipmentAlert', {
        index: shipmentAlertIndex,
        ...data
      })
    }
  },
  async deleteShipmentAlert({ commit, state, dispatch }, shipmentAlertId) {
    const { id: clientId } = this.$auth.user
    try {
      await this.$axios.$delete(
        `${USERS_API_URL}/client/${clientId}/search/alerts/${shipmentAlertId}`,
        {
          progress: false
        }
      )
      const shipmentAlertIndex = state.shipmentAlerts.findIndex(
        shipmentAlert => shipmentAlert.shipmentAlertId === shipmentAlertId
      )
      if (shipmentAlertIndex > -1) {
        commit('deleteShipmentAlert', shipmentAlertId)
      }

      if (shipmentAlertId === state.shipmentAlertId)
        commit('setShipmentAlertStatus', Status.ACTIVE)

      dispatch('userCredits/getUserCredits', null, { root: true })
    } catch (error) {
      throw error
    }
  }
}
